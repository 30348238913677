<template>
  <div class="lottery-today">
    <div class="header">
      <div class="title-box">
        <p class="title">近期号码</p>
        <intro-default cont="提供近期号码的开出次数及近期未开次数，使用参数设置可自定义关注遗漏范围，请根据个人需要手动设置，各参数的数值可设置范围是：>=0。"></intro-default>
      </div>
      <div class="search-box">
        <div class="color-setting">
          <div class="settings">
            <div class="item" v-for="(option, i) in inputOptions" :key="i">
              <div class="input">
                <span>参考设置:</span>大于<input type="number" v-model.number="option.num">时为<i
                  :style="{ 'background-color': option.color }"></i>色
              </div>
            </div>
            <div class="confirm">
              <div @click="colorSetting">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lz_cont">
      <div class="table">
        <el-table :data="data" border style="width: 100%" highlight-current-row
          header-cell-class-name="table-header-cell" cell-class-name="table-cell" row-class-name="table-row"
          :cell-style="cellStyle" size="small" row-key="id" id="table">
          <el-table-column label="号码" align="center" min-width="53">
            <template #default="scope">
              <div class="num_box">
                <number v-if="code != 'dongganpuke'" :value="get_num(scope.row[0]['number'])" :lottery-code="code">
                </number>
                <span v-else v-html="get_num(scope.row[0]['number'])"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="name" align="center" v-for="(name, i) in rankingNames" :key="i">
            <el-table-column label="总开" align="center" :width="columnWidth">
              <template #default="scope">
                {{ scope.row[i].open }}
              </template>
            </el-table-column>
            <el-table-column label="未开" align="center" :width="columnWidth">
              <template #default="scope">
                {{ scope.row[i].not_open }}
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import Number from '@components/pc/Number'

const options = [
  {
    num: 0,
    color: '#FA3E3E'
  }
]

const clone = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}

const kRankingNames = ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名']
const kTableWidth = 1237
const kNumberColumnWidth = 53

export default {
  name: 'lotteryToday',
  props: {
    code: String
  },
  components: {
    [Number.name]: Number,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      data: [],
      loading: null,
      // rankingNames: kRankingNames,
      colorOptions: clone(options),
      inputOptions: clone(options)
    }
  },
  computed: {
    rankingNames() {
      if (this.data.length === 0) return []
      return this.get_type_name(this.code)
    },
    columnWidth() {
      if (this.rankingNames.length === 0) return ''
      return parseInt((kTableWidth - kNumberColumnWidth) / this.rankingNames.length / 2)
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    get_num(data) {
      if (this.code === 'dongganshengxiao') {
        const arr = ['', '鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
        return arr.indexOf(data)
      }
      if (this.code === 'dongganpuke') {
        return '<span style="color: #07A6F8;">' + data + '</span>'
      }
      return data
    },
    refresh() {
      this.requestGetHistory()
    },
    requestGetHistory() {
      this.showTableLoading()
      this.$api.todayNumber(this.code).then((res) => {
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    colorSetting() {
      this.colorOptions = clone(this.inputOptions)
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) return
      const i = parseInt((columnIndex - 1) / 2)
      if (columnIndex % 2 === 1) return
      const value = row[i].not_open
      for (let index = 0; index < this.colorOptions.length; index++) {
        const { num, color } = this.colorOptions[index]
        if (value > num) {
          return { color }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-today {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      flex: 1;
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .info {
    margin-top: 25px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: bold;
    }
  }

  .switch {
    list-style: none;
    padding: 0;
    margin: 32px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    >li {
      cursor: pointer;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      height: 34px;
      line-height: 34px;

      &.active {
        color: #fa3e3e;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 43px;
          height: 3px;
          background: #fa3e3e;
          box-shadow: 0px 2px 4px 0px rgba(250, 62, 62, 0.2);
          border-radius: 8px;
          left: 50%;
          transform: translate(-50%);
          bottom: 0;
        }
      }

      &+li {
        margin-left: 125px;
        font-weight: 500;
      }
    }
  }

  .color-setting {
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >.head {
      margin-right: 18px;

      >p {
        font-size: 14px;
        font-weight: bold;
        color: #222;
      }
    }

    >.settings {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      >.item {
        &+.item {
          margin-left: 40px;
        }

        display: flex;
        align-items: center;

        >.input {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #959595;
          flex-grow: 1;

          span {
            margin-right: 6px;
            color: #FA3E3E;
          }

          input {
            width: 32px;
            height: 24px;
            color: #959595;
            border: 1px solid #E3E3E5;
            border-radius: 4px;
            box-sizing: border-box;
            text-align: center;
            background: none;
            outline: none;
            margin-left: 6px;
            margin-right: 6px;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }

            &[type="number"] {
              -moz-appearance: textfield;
            }
          }

          i {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-left: 6px;
            margin-right: 6px;
          }
        }

        >.btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 9px;
          border-left: 1px solid rgba(216, 216, 216, 0.5);

          >img {
            cursor: pointer;
          }
        }
      }

      >.confirm {
        margin-left: 36px;
        display: flex;
        justify-content: flex-end;

        >div {
          width: 86px;
          height: 28px;
          background: #FA3E3E;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}

.num_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.lottery-today .table {
  margin-top: 20px;
}

.lottery-today .table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-today .table .table-header-cell>.cell {
  padding: 0;
}

.lottery-today .table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.lottery-today .table .table-cell>.cell {
  padding: 0;
}

.lottery-today .table .table-row:hover>td,
.lottery-today .table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
